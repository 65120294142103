import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import counterStrikeLogo from "../images/CSGO-logo-white.svg"
import fortniteLogo from "../images/FortniteLogoWhite.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Spil" />
    <section>
      <h1>Vores Spil</h1>
      <p>
        Vi tilbyder undervisning og sociale arrangementer indenfor de mest populære spil.<br />
      </p>
    </section>
    <section className="games">
      <div className="game-card">
        <div className="game-header">
          <img src={counterStrikeLogo} alt="Counter-Strike" />
        </div>
        <div className="game-info">
          <h5>Counter-Strike:Global Offensive</h5>
          <p>
            Om du er silver, gold eller global er underordnet - vi sørger for den rette træning til lige netop dig.<br />
            Vi deltager i turneringer og ligaer, holder hyggeaftener og tager til arrangementer!<br />
            Skal du være med?
          </p>
        </div>
        <div className="subscribe">
          <Link to="/tilmelding">Tilmeld</Link>
        </div>
      </div>
      <div className="game-card">
        <div className="game-header">
          <img src={fortniteLogo} alt="Fortnite" />
        </div>
        <div className="game-info">
          <h5>Fortnite</h5>
          <p>
            Fortnite er et hit og vi har massere af interesse for spillet, men desværre ingen træner.<br />
            Er det dig? Så kontakt os på vores email eller via. Facebook.
          </p>
        </div>
        <div className="subscribe">
          <Link to="/tilmelding">Tilmeld</Link>
        </div>
      </div>
      <div className="game-card">
        <div className="game-header">
          ?
        </div>
        <div className="game-info">
          <h5>Næste spil?</h5>
          <p>
            Vi leder altid efter nye hold, så du står med en idé, er vi meget interesseret!<br />
            Send os en mail på kontakt@bi-esport.dk.
          </p>
        </div>
        <div className="subscribe">
          <Link to="/kontakt">Kontakt</Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
